<template>
  <div
    v-if="isVisible"
    :class="['modal fade', ...containerCss]"
    :id="componentName"
    data-toggle="modal"
    :data-backdrop="isModalStatic ? 'static' : ''"
    :data-keyboard="isModalStatic ? 'false' : 'true'">
    <div :class="['modal-dialog', ...modalCss]">
      <div class="modal-content">
        <div class="modal-header" v-if="!isHideHeaderCloseButton">
          <slot name="title"></slot>
          <slot name="button" v-if="!isHideHeaderCloseButton">
            <a
              class="btn btn-close"
              href="javascript:void(0);"
              data-dismiss="modal"
              aria-label="Close"
              @click="cancelHandler">
              <i class="icon icon-close"></i>
            </a>
          </slot>
        </div>
        <div :class="['modal-body', ...bodyCss]">
          <slot name="body" />
        </div>
        <div class="modal-footer" v-if="!isHideFooter">
          <slot name="footer">
            <button
              type="button"
              :class="['btn', firstButtonCss]"
              data-dismiss="modal"
              @click="firstFnHandler"
              v-if="firstButtonText">
              {{ firstButtonText }}
            </button>
            <button
              type="button"
              :class="['btn', secondButtonCss]"
              @click="secondFnHandler"
              v-if="secondButtonText"
              data-dismiss="modal">
              {{ secondButtonText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { renderState } from '@/helpers/vuex';
import { computed, onUnmounted } from 'vue';
import { isMobile } from '@/helpers/is-from-app';
import { closeModal } from '@/helpers/modal';

export default {
  name: 'common-modal',
  props: {
    componentName: {
      type: String,
      required: true,
    },
    firstButtonText: {
      type: String,
      default: '',
    },
    secondButtonText: {
      type: String,
      default: '',
    },
    isHideHeaderCloseButton: {
      type: Boolean,
      default: false,
    },
    isHideFooter: {
      type: Boolean,
      default: false,
    },
    containerCssClasses: {
      type: String,
      default: '',
    },
    bodyCssClasses: {
      type: String,
      default: '',
    },
    modalSizeCssClass: {
      type: String,
      default: '',
    },
    // 鎖定背景，點擊背景時不自動關閉視窗
    isModalStatic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, target) {
    const isVisible = computed(() => renderState('Modal', `isShow${props.componentName}`));
    const containerCss = computed(() => [props.containerCssClasses]);
    const bodyCss = computed(() => [props.bodyCssClasses]);
    const modalCss = computed(() => [props.modalSizeCssClass && `modal-${props.modalSizeCssClass}`]);
    const isButtonQuantityGreaterThanOne = computed(() => !!props.firstButtonText && !!props.secondButtonText);
    const isMobileDevice = computed(() => isMobile());
    const firstButtonCss = computed(() =>
      isMobileDevice.value ? 'btn-round btn-query' : isButtonQuantityGreaterThanOne.value ? 'btn-default' : 'btn-query'
    );
    const secondButtonCss = computed(() => (isMobileDevice.value ? 'btn-round btn-primary' : 'btn-query'));
    const firstFnHandler = () => {
      closeModal(props.componentName);
      target.emit('firstFn');
    };
    const secondFnHandler = () => {
      closeModal(props.componentName);
      target.emit('secondFn');
    };
    const cancelHandler = () => {
      closeModal(props.componentName);
      target.emit('cancel');
    };
    // edge case: user在modal開啟的狀態下直接按上一頁
    onUnmounted(() => {
      if (document.querySelector('.modal-backdrop') !== null) {
        document.querySelector('body').classList.remove('modal-open');
        document.querySelector('.modal-backdrop').classList.remove('modal-backdrop');
      }
    });
    return {
      isVisible,
      containerCss,
      bodyCss,
      modalCss,
      isButtonQuantityGreaterThanOne,
      firstButtonCss,
      secondButtonCss,
      firstFnHandler,
      secondFnHandler,
      cancelHandler,
    };
  },
};
</script>

<style scoped>
#edenredModal.isOpenLoading {
  z-index: 1000;
}
</style>
