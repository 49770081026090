/**
 * format dollar
 * @param {Number} val
 * @return {String}
 */
export const dollar = (val) => `$${val.toLocaleString()}`;

/**
 * 百分比
 * @param {Number} val 數字
 * @return {string} 百分比 format
 */
export const percent = (val) => (val === 0 ? `${val}` : `${val}%`);

export const NumberWithPoint = (val) => `${val.toLocaleString()}`;

export const numPadZeroes = (num) => {
  if (Number.isNaN(num) || !Number.isFinite(num) || num === 0) {
    return num;
  }
  return num.toFixed(2);
};
