<template>
  <modal
    :isModalStatic="true"
    componentName="LoginModal"
    firstButtonText="登入/註冊"
    secondButtonText="直接結帳"
    @firstFn="redirectToLogin"
    @secondFn="$emit('check-out')">
    <template v-slot:body>
      <template v-if="isMobile">
        <h4 class="text-center">您尚未登入會員，</h4>
        <h4 class="text-center">請登入/註冊使用會員相關折抵優惠。</h4>
      </template>
      <template v-else>
        <h3 class="align center">
          您尚未登入會員，
          <br />
          請登入/註冊使用會員相關折抵優惠
        </h3>
      </template>
    </template>
  </modal>
</template>
<script>
import { redirectToLogin } from '@/store/modules/step1/cart-detail';
import Modal from '@/components/common/common-modal.vue';
import { isMobile } from '@/helpers/is-from-app';

export default {
  name: 'LoginModal',
  components: {
    Modal,
  },
  computed: {
    isMobile,
  },
  methods: {
    redirectToLogin,
  },
};
</script>
