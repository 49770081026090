<template>
  <modal
    componentName="HamipointModal"
    :modalSizeCssClass="isMobile ? '' : 'sm'"
    firstButtonText="取消"
    secondButtonText="同意"
    :isModalStatic="true"
    @cancel="$emit('cancel')"
    @firstFn="$emit('cancel')"
    @secondFn="redirectToCht">
    <template v-slot:body>
      <h3 class="align center">結帳使用 Hami Point 折抵</h3>
      <p class="align center">
        您將前往「中華電信-會員中心」
        <br />
        進行會員身分驗證。
      </p>
    </template>
  </modal>
</template>
<script>
import { isMobile } from '@/helpers/is-from-app';
import { redirectToCht } from '@/mixins/cart/main/partial';
import Modal from '@/components/common/common-modal.vue';

const components = {
  Modal,
};

export default {
  name: 'auth-hamipoint-alert-modal',
  components,
  methods: {
    redirectToCht,
  },
  computed: {
    isMobile,
  },
};
</script>
