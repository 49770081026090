<template>
  <div v-if="isShowCycleOrder">
    <!-- 桌機版 at mart-list.vue -->
    <div class="cart_cycle" v-if="!isMobile">
      <img alt="週期購" src="/images/icons/icon_cycle.png" />
      <div class="cart_cycle_text">
        <div class="product-type">
          <span class="spacing right">週期間隔</span>
          <select name="selectInterval" @change="handleCommonCycleInterval($event)">
            <option
              v-for="(day, index) in item.intervals"
              :key="`cycleDay-${day}-${index}`"
              :value="day"
              :selected="item.interval === day">
              <span>{{ day }} 天</span>
            </option>
          </select>
          <span class="spacing right cycle_fq">配送次數(含本次)</span>
          <select @change="handleCommonCycleTimes($event)">
            <option
              v-for="(time, key) in filterCyclePeriodTimes(item.allTimes)"
              :key="`cycleTime-${time.value}-${key}`"
              :value="time.value"
              :selected="time.value === item.times">
              {{ time.periodTimesTxt }}
            </option>
          </select>
          <p>
            每隔<span class="cycle_s">{{ item.interval }}天</span>為您配送<span class="cycle_s"
              >{{ item.productQty }}組</span
            >商品；訂購次數含本次，<span v-if="checkCycleTimesType(item.times)">總共訂購&nbsp;</span
            ><span class="cycle_s">{{ getTimesLabel(item.allTimes, item.times) }}。</span>
            <span class="cycle_s">限使用信用卡一次付款</span>，將依您選擇的訂購週期次數，自動續訂購該項商品。
          </p>
        </div>
      </div>
    </div>
    <!-- 手機版 -->
    <div v-else>
      <div class="product-option-select spacing bottom top mini">
        <label class="spacing bottom mini has-block">每次週期間隔</label>
        <select name="selectInterval" @change="handleCommonCycleInterval($event)">
          <option
            v-for="(day, index) in item.intervals"
            :key="`cycleDay-${day}-${index}`"
            :value="day"
            :selected="item.interval === day">
            <span>每隔 {{ day }} 天一次</span>
          </option>
        </select>
      </div>
      <div class="product-option-select spacing bottom mini">
        <label class="spacing bottom mini has-block">訂購週期次數(含本次)</label>
        <select @change="handleCommonCycleTimes($event)">
          <option
            v-for="(time, key) in filterCyclePeriodTimes(item.allTimes)"
            :key="`cycleTime-${time.value}-${key}`"
            :value="time.value"
            :selected="time.value === item.times">
            {{ time.periodTimesTxt }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { useCycle } from '@/composables/cycleOrder';
import { getTimesLabel, checkCycleTimesType } from '@/helpers/cycle-order-content';

const props = {
  isMobile: Boolean,
  item: Object,
  itemIndex: Number,
};

const methods = {
  checkCycleTimesType,
  getTimesLabel,
};

export default {
  name: 'cycle-order',
  props,
  methods,
  setup(props) {
    const {
      isShowCycleOrder,
      renderCycleIntervalText,
      renderCycleTimesText,
      filterCyclePeriodTimes,
      handleCommonCycleInterval,
      handleCommonCycleTimes,
    } = useCycle(props);
    return {
      isShowCycleOrder,
      renderCycleIntervalText,
      renderCycleTimesText,
      filterCyclePeriodTimes,
      handleCommonCycleInterval,
      handleCommonCycleTimes,
    };
  },
};
</script>
