import { cartTypes } from '@/constant/cart-enum';
import { getCookie, getUtm } from '@/helpers/cookie';
import { callMutation, commitMutation, getAction } from '@/helpers/vuex';
import { isMobile } from '@/helpers/is-from-app';

// 桌機 + 手機
export const cartMainMountedMixin = {
  mounted() {
    const utm = getCookie('utm');
    const utmForDisplay = getUtm(utm);
    if (utmForDisplay) {
      commitMutation('CartDetail', 'utm', utmForDisplay);
    }
    const url = new URL(window.location.href);
    const toCartType = url.searchParams.get('type');
    const getCartType = parseInt(toCartType, 10);
    // 檢查是否為合法路徑
    const isCartType = cartTypes.indexOf(getCartType) > -1;
    // 如為非法路徑，傳送至各自裝置的對應 cartType
    if (isMobile() && isCartType === false) {
      this.$router.replace({ path: '/m/cart/choice' });
      return;
    }
    // 打 [getCartDetail] API 取得購物車內容
    if (isCartType) {
      callMutation('PaymentInfo', 'RESET_STATE');
      // 重置 step2 的付款組件
      commitMutation('DynamicComponents', 'paymentMethod', '');
      // 防止重複 push
      commitMutation('AmountDetail', 'getDiscountList', []);
      // 寫入 vuex ; 供呼叫 cart-detail API 用
      commitMutation('CartDetail', 'cartType', toCartType);
      // 取得購物車內容
      getAction('CartDetail', 'fetchCartDetail')();
    }
  },
};
