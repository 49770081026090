import { isMobile } from '@/helpers/is-from-app';

const CURRENCY = 'TWD';

const reinitialize = () => {
  if (typeof Insider === 'object') {
    Insider.eventManager.dispatch('init-manager:re-initialize');
  }
};

const sendInitializeHit = () => {
  if (typeof Insider === 'object' && typeof Insider.initializeHitAPI === 'function') {
    Insider.initializeHitAPI();
  }
};

const detectAppWebviewOS = () => {
  const browserAgent = String(window.navigator.userAgent).toLowerCase();
  let browserOS = '';
  let isWebview = false;
  if (/android/.test(browserAgent)) {
    browserOS = 'android';
    isWebview = / wv/.test(browserAgent);
  } else if (/iphone|ipod|ipad/.test(browserAgent)) {
    browserOS = 'ios';
    isWebview = !/safari/.test(browserAgent);
  }
  return browserOS !== '' && (isWebview || /webview/.test(browserAgent)) ? browserOS : '';
};

const makeAppWebviewPageConfirmation = (orderId, details) => {
  const data = details.map((detail) => {
    return {
      saleID: orderId,
      product: {
        name: detail.productName,
        productID: detail.martCode,
        imageURL: detail.masterImage || '',
        currency: CURRENCY,
        taxonomy: Object.values(detail.categories),
        custom: { product_no: [detail.productNo] },
        price: Number(detail.finalPrice),
        quantity: Number(detail.productQty),
      },
    };
  });
  return JSON.stringify(data);
};

const sendWebBrowserPageConfirmation = (orderId) => {
  if (typeof Insider !== 'object') {
    return;
  }

  window.insider_object = window.insider_object || {};
  window.insider_object.transaction = {
    order_id: orderId,
  };

  window.insider_object.page = {
    type: 'Confirmation',
  };

  sendInitializeHit();

  const ajaxListenerConfirmation = function (callback) {
    var oldOpen = XMLHttpRequest.prototype.open;

    XMLHttpRequest.prototype.open = function (method, url) {
      this.addEventListener('readystatechange', function () {
        if (
          this.responseType !== 'arraybuffer' &&
          this.responseType !== 'blob' &&
          this.readyState === 4 &&
          this.status === 200 &&
          typeof callback === 'function'
        ) {
          callback(url, this.response, method);
        }
      });

      oldOpen.apply(this, arguments);
    };
  };

  ajaxListenerConfirmation(function (url, response, method) {
    if (
      method.toLowerCase() === 'post' &&
      url.indexOf('/hit') > -1 &&
      ((window.insider_object || {}).page || {}).type === 'Confirmation'
    ) {
      setTimeout(function () {
        delete (window.insider_object || {}).page;
      }, 2000);
    }
  });
};

/**
 * 建立 parameter for Insider's basket object line_items properties.
 * @param {Array} cartDetail
 * @return {Array}
 * */
export const createBasketObject = (cartDetail) => {
  return cartDetail.map((item) => {
    return {
      product: {
        id: item.martCode,
        name: item.productName,
        taxonomy: Object.values(item.categories),
        currency: CURRENCY,
        url: isMobile() ? `${process.env.VUE_APP_ONLINE}${item.mbLink}` : `${process.env.VUE_APP_ONLINE}${item.pcLink}`,
        product_image_url: item.masterImage,
        unit_price: item.unitPrice,
        unit_sale_price: item.finalPrice,
        custom: {
          product_no: [item.productNo],
        },
      },
      quantity: Number(item.productQty),
      subtotal: item.finalPrice * item.productQty,
    };
  });
};

/**
 * 送出 Insider's Basket Object event
 * @param {Object} basketParameterForInsider
 * doc: https://academy.useinsider.com/docs/insider-object-integration-basket-object
 */
export const sendBasketObject = (cartAmount, cartDetail) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  window.insider_object = window.insider_object || {};
  try {
    const lineItemsForInsider = createBasketObject(cartDetail);
    const basketParameterForInsider = {
      currency: CURRENCY,
      total: Number(cartAmount),
      line_items: lineItemsForInsider,
    };
    // console.log(basketParameterForInsider);
    window.insider_object.basket = basketParameterForInsider;
    sendInitializeHit();
  } catch (err) {
    console.log('sent Insider basket object error');
    console.log(err);
  }
};

export const sendPageConfirmation = (orderId, details) => {
  const appFunctionName = 'sendDataToApp';
  try {
    switch (detectAppWebviewOS()) {
      case 'android':
        app[appFunctionName](makeAppWebviewPageConfirmation(orderId, details));
        break;
      case 'ios':
        window.webkit.messageHandlers[appFunctionName].postMessage(makeAppWebviewPageConfirmation(orderId, details));
        break;
      default:
        sendWebBrowserPageConfirmation(orderId);
        break;
    }

    reinitialize();
  } catch (e) {
    console.log(`sent Insider page confirmation error: ${orderId}`);
    console.log(e);
  }
};
