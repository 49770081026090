import { renderState } from '@/helpers/vuex';
import { isMobile } from '@/helpers/is-from-app';
import { setCycleParams } from '@/helpers/cycle-order-content';

/**
 * 建立結帳參數
 * @return {Object} formData
 */
export function setCheckParams() {
  const pickUsePoint = renderState('CartDetail', 'isCheckPoint');
  const point = renderState('CartDetail', 'point');
  const selectCoupon = renderState('CartDetail', 'selectCoupon');
  const selectEmp = renderState('CartDetail', 'selectEmp');
  const deliveryType = renderState('DeliveryInfo', 'delivery');
  const getCartDetail = renderState('CartDetail', 'cartDetail');
  const cloneCartDetail = getCartDetail.map((x) => ({ ...x }));
  const selectCycle = setCycleParams(cloneCartDetail);
  const formData = new FormData();
  formData.append('usePoint', !pickUsePoint ? 0 : `${point}`);
  formData.append('useDiscount', 0);
  if (isMobile()) {
    formData.append('deliveryType', deliveryType);
  }
  formData.append('coupons', JSON.stringify(selectCoupon));
  formData.append('employees', JSON.stringify(selectEmp));
  formData.append('cycles', JSON.stringify(selectCycle));
  return formData;
}
