import { cartMainCheckout, collectProduct, deleteProduct, deleteAdditional } from '@/api/cart-main';
import {
  addToCart,
  removeFromCart,
  sendGA4sEvent,
  createParameterArray,
  createParameterObj,
  checkOutToCart,
} from '@/helpers/tracking/ga';
import { sendBasketObject } from '@/helpers/tracking/insider';
import {
  renderState,
  commitMutation,
  commitMutations,
  dispatchAction,
  renderGetter,
  callMutation,
} from '@/helpers/vuex';
import { getCityIdByName, getTownIdByName, regexpAddress } from '@/helpers/city-town';
import { getNearbyStoreFn } from '@/helpers/getNearbyStore';
import { isMobile, isFromMuchMuch } from '@/helpers/is-from-app';
import { openModal } from '@/helpers/modal';
import { setCheckParams } from '@/helpers/format/checkout-param';
import { equals, prop } from 'ramda';
import { paymentEnum, arrangeMobilePaymentEnum, paymentStatusEnum } from '@/constant/payment-info';
import { setProp } from '@/helpers/object';
import { componentLut } from '@/components/desktop/step1/payment-method/constant/payment-method.constant';

const getCartDetail = () => renderGetter('CartDetail', 'getterCartDetail');

const getCouponList = () => renderGetter('CartDetail', 'getterCoupon');

// 取得商品數量, 贈品除外
const getCartDetailCountFilterGift = function () {
  return this.getCartDetail.filter((x) => x.cartTag !== '3').length;
};

// 是否為主商品 or 活動折扣主商品
const isMajorItem = () => (item) => item === '0' || item === '1';

/** 商品數量增減運算
 * - 減少數量
 * - 增加數量
 * - user 自行輸入數量
 */

const changeQty = function (item, mode) {
  const reduce = item.productQty - 1 < 1 ? 1 : item.productQty - 1;
  const increase = item.productQty + 1 > item.limitQuantity ? item.limitQuantity : item.productQty + 1;
  const newQty = mode === 'reduce' ? reduce : increase;
  dispatchAction('CartDetail', 'getProductQty', { item, newQty });
  // for GA
  mode === 'reduce' ? removeFromCart([item], item.martCode) : addToCart([item], item.martCode);
};

const customQty = function (event, item) {
  const { value } = event.target;
  let newQty = Number.isInteger(parseInt(value, 10)) ? parseInt(value, 10) : 1;
  newQty = newQty < 1 ? 1 : newQty;
  newQty = newQty > item.limitQuantity ? item.limitQuantity : newQty;
  event.target.value = newQty;
  // 呼叫 [updateQuantity]API 即時更新數量
  dispatchAction('CartDetail', 'getProductQty', { item, newQty });
};

/** 商品移除與移至收藏匣
 * - 收藏或移除商品共用行為 -> removeFromGA -> 重新 render view
 * - 移到收藏夾
 * - 移除商品
 */

const isRemoveProduct = function (mart) {
  // ga remove product，挑出該商品並從 ga 移除
  const gaResponse = (item) => item.detailId === mart.detailId || item.parentId === mart.detailId;
  const gaCartList = this.getCartDetail.filter(gaResponse);
  const insiderCartList = this.getCartDetail.filter((item) => !gaResponse(item));
  removeFromCart(gaCartList, mart.martCode);
  if (insiderCartList.length === 0) {
    sendBasketObject(0, []);
  }

  // 在移除或收藏該商品時，如 user 有選擇該商品的折價券，則清除存在 Vue Data-selectedCoupon 該商品與更新 vuex
  if (this.selectedCoupon.length > 0) {
    const filterSelectCoupon = (item) => item.couponId !== mart.couponId;
    this.selectedCoupon = this.selectedCoupon.filter(filterSelectCoupon);
    commitMutation('CartDetail', 'selectCoupon', this.selectedCoupon);
  }
  // 挑出 user 選擇員購價的該商品，清除存在 Vue Data-selectedEmp 該商品與更新 vuex
  if (this.selectedEmp.length > 0) {
    const filterSelectEmp = (item) => item.detailId !== mart.detailId;
    this.selectedEmp = this.selectedEmp.filter(filterSelectEmp);
    commitMutation('CartDetail', 'selectEmp', this.selectedEmp);
  }
  // filter 主商品 贈品 加購品 ; 挑出非被移除的商品重新排列 at cartMain 頁
  const response = (item) => item.detailId !== mart.detailId && item.parentId !== mart.detailId;
  const updateCartDetail = this.getCartDetail.filter(response);
  // 取得商品數量, 贈品除外
  const cartDetailLength = updateCartDetail.filter((x) => x.cartTag !== '3').length;
  const thisCartType = renderState('CartDetail', 'cartType');
  // update cart count
  this.$store.commit('Cart/changeCartCount', {
    cartType: thisCartType,
    count: cartDetailLength,
    updateList: updateCartDetail,
  });
};

const collectProducts = function (item) {
  const isLogin = renderState('CartDetail', 'isLogin');
  if (!isLogin) {
    alert('請先登入神腦生活會員');
    const redirectPath = isMobile() ? '/m/Member/Login?redirect=' : '/login?redirect=';
    const redirectPathPage = isMobile() ? '/m/cart/choice' : '/cart/main';
    window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPath}${encodeURIComponent(
      process.env.VUE_APP_CART
    )}${redirectPathPage}`;
    return;
  }
  const response = (res) => {
    if (res.data.code !== 1) {
      alert(res.data.description);
      window.location.reload();
      return;
    }
    this.isRemoveProduct(item);
    const itemParameters = createParameterObj(item);
    const defaultParameterForGA = {
      currency: 'TWD',
      value: item.oriPrice,
      items: itemParameters,
    };
    sendGA4sEvent('add_to_wishlist', defaultParameterForGA);
    alert('加入收藏夾成功');
  };
  const formData = new FormData();
  formData.append('martCode', item.martCode);
  formData.append('productNo', item.productNo);
  formData.append('useFor', 'ECOMS');
  collectProduct(formData)
    .then(response)
    .catch(() => window.location.reload());
};

const deleteProducts = function (item) {
  const formData = new FormData();
  formData.append('martCode', item.martCode);
  formData.append('productNo', item.productNo);
  // 刪除加價購使用
  formData.append('detailId', item.detailId);
  const response = (res) => {
    if (res.data.code !== 1) {
      alert(res.data.description);
      window.location.reload();
    }
  };
  const remove = () => {
    this.isRemoveProduct(item);
  };
  // 刪除加價購
  if (item.cartTag === '2') {
    deleteAdditional(formData)
      .then(response)
      .then(remove)
      // 重刷頁面
      .catch((error) => {
        console.log(error);
        alert('刪除失敗！');
        window.location.reload();
      });
    return;
  }
  // 刪除主商品
  deleteProduct(formData)
    .then(response)
    .then(remove)
    // 重刷頁面
    .catch((error) => {
      console.log(error);
      alert('刪除失敗！');
      window.location.reload();
    });
};

/**
 * - 是否顯示折扣價 TAG (需為主商品且未選取優惠方案才出現 tag)
 * - 是否顯示折扣活動
 */

const isShowDiscountTag = () => (item) => item.cartTag === '1' && !item.selectPricingPlan;

const isShowDiscount = () => (item) => item.discountName !== '' && item.cartTag === '1';

/** 優惠方案、折價券
 * - 是否顯示員購價選項 (API Data 裡有 empPrice flag 才是 true)
 * - 呼叫優惠券 API 是否成功
 * - 過濾出商品可使用的優惠券供 isShowPricingPlans 判斷
 * - 判斷是否顯示優惠方案 select
 * - 判斷使用者是否有重複使用優惠券的行為 ; 例如同金額的優惠券不能同時使用在同一車即使商品不同
 * - user 選擇優惠方案
 */
const isShowCoupon = function () {
  return this.getCouponList.length > 0;
};

const isShowHasCoupon = function (item) {
  const martIdMatchMartCode = this.getCouponList.filter(
    (x) => x.martId.includes(item.martCode) || x.martId.includes(parseInt(item.martCode, 10))
  );
  return martIdMatchMartCode.length > 0;
};

const isShowEmpPrice = () => (item) => Object.keys(item).includes('empPrice');

const isShowPricingPlans = function (item) {
  return this.isShowHasCoupon(item) || this.isShowEmpPrice(item);
};

// user 選擇優惠方案
const onPricingChange = function ({ couponId, cartIndex }) {
  const resetCartDetail = this.getCartDetail.map((x) => ({ ...x }));
  const selfDetail = resetCartDetail[cartIndex];
  // user 選取的 option value
  const selectValue = couponId;
  // user 選取的優惠券 ; 不能是已選過的優惠券 ; 暫時存放 user 使用了哪些優惠券的陣列 ; 用來 post Data 用
  this.selectedCoupon = this.selectedCoupon.filter((x) => x.couponId !== selfDetail.couponId);
  this.selectedEmp = this.selectedEmp.filter((x) => x.detailId !== selfDetail.detailId);
  // 撈出 user 選中該優惠券的 API data
  const couponInfo = this.getCouponList.find((x) => x.couponId === selectValue);
  // 清空 coupon 資訊與還原價格
  let setCouponInfo = {
    couponId: '',
    couponDiscount: 0,
    couponDesc: '',
    finalPrice: selfDetail.oriPrice,
    selectPricingPlan: false, // 是否選取優惠方案
  };
  switch (selectValue) {
    // user 選擇使用員購價
    case 'emp':
      setCouponInfo = {
        couponId: 'emp',
        couponDiscount: 0,
        couponDesc: '員購價',
        finalPrice: selfDetail.empPrice,
        selectPricingPlan: true, // 是否選取優惠方案
      };
      this.selectedEmp.push({ detailId: selfDetail.detailId, price: selfDetail.empPrice });
      break;
    // user 選擇不使用優惠方案
    case '':
      break;
    // user 選擇使用優惠券
    default:
      // 寫入 coupon 資訊與價格使用 salePrice
      setCouponInfo = {
        couponId: couponInfo.couponId,
        couponDiscount: couponInfo.itemCoupon,
        couponDesc: couponInfo.couponDesc,
        finalPrice: selfDetail.salePrice,
        selectPricingPlan: true,
      };
      this.selectedCoupon.push({ couponId: couponInfo.couponId, detailId: selfDetail.detailId });
      break;
  }
  resetCartDetail[cartIndex] = Object.assign({}, selfDetail, setCouponInfo);
  // 更新 store
  commitMutation('CartDetail', 'selectEmp', this.selectedEmp);
  commitMutation('CartDetail', 'selectCoupon', this.selectedCoupon);
  commitMutation('CartDetail', 'cartDetail', resetCartDetail);
  if (isMobile()) {
    // 呼叫重新排列手機版 cartMain fn()
    return dispatchAction('CartDetail', 'getMobileCartMain');
  }
};

/** 有關紅利 & HamiPoint Component: [PC]:discount-total | [Mobile]:check-bonus / total-amount
  - 是否秀出紅利折抵選項 ; isBonus = 1 且 神腦幣 > 0 ; R 賣場的商品的 isBonus 一定為 0
  - 已登入且非從 much much App 來的才能顯示 HamiPoint 區塊
  - 只有非從 much much App 來的才能顯示宜睿
  - 是否已授權 HamiPoint 取點數
  - 可用 HamiPoint 點數
  - 同意即跳轉至 [中華電信-會員中心] 會員身份驗證頁
  - 取得宜睿 API 內容
  - 取得宜睿券序號
  - 取得宜睿券價格
  - 是否有宜睿券 ; className and disable 控制
  - 是否顯示宜睿可用餘額欄位 at modal
  - 可用神腦幣
  - 紅利金額字串化 ; 不做運算用供顯示
  - 判斷是否勾選使用紅利，並回傳計算過後的神腦幣金額
  - 比率
  - 隨增減可折商品數量變更可用神腦幣及金額
*/

export const hasPoint = function () {
  const isBonus = this.getCartDetail.some((x) => x.isBonus);
  const pointMoney = renderState('CartDetail', 'pointMoney');
  return isBonus && pointMoney > 0;
};

const isShowHamiPointBlock = () => {
  const isLogin = renderState('CartDetail', 'isLogin');
  const isNotFromO2R = renderState('Cart', 'isFromApp') !== 2;
  return isLogin && isNotFromO2R;
};

const isAuthHamiPoint = () => renderState('CartDetail', 'isAuthHamiPoint');

const hamiPoint = () => renderState('CartDetail', 'hamiPoint');

export const redirectToCht = () => {
  window.location.href = `${process.env.VUE_APP_ONLINE}/oauth/chtaccount/auth?redirect=${encodeURIComponent(
    window.location.href
  )}`;
};

const point = () => renderState('CartDetail', 'point');

const formatPointMoney = () => renderGetter('CartDetail', 'getterPointMoney');

const pointMoney = () => {
  let point = renderState('CartDetail', 'pointMoney');
  const isCheck = renderState('CartDetail', 'isCheckPoint');
  if (isCheck) {
    return point;
  }
  return 0;
};

const pointMaxExchangeRate = function () {
  return renderGetter('CartDetail', 'getterCartPoint').pointMaxExchangeRate;
};

const updatePoint = () => callMutation('CartDetail', 'setCalculation');

/** 有關金額
 * - 取 0 以上的值
 * - 該車全部商品小計原始總和 (前提為未扣紅利或折價券 ; 如溢出則強制為 $0 )
 * - 總計 = 小計總和 - 紅利金額
 * - 可折商品小計總和 ; 篩選出該車可折商品之小計總和，用來給修改商品數量時，神腦幣即時運算
 */

const getMaximum = (x) => Math.max(x.finalPrice * x.productQty - x.couponDiscount, 0);

const subTotal = function () {
  const subTotal = this.getCartDetail.reduce((sum, x) => sum + getMaximum(x), 0);
  commitMutation('CartDetail', 'subTotal', subTotal);
  return subTotal;
};

const amount = function () {
  const total = this.subTotal - this.pointMoney;
  const amountTotal = total < 0 ? 0 : total;
  // 當為 0 元商品或非因紅利全抵時，不顯示紅利，其 checkbox 也取消勾選，怕呼叫 checkOut API時送出紅利參數
  if (this.subTotal === 0) {
    commitMutation('CartDetail', 'isCheckPoint', false);
  }
  commitMutation('CartDetail', 'amount', amountTotal);
  const cartDetail = renderState('CartDetail', 'cartDetail');
  if (cartDetail && cartDetail.length) {
    sendBasketObject(amountTotal, cartDetail);
  }
  return amountTotal;
};

const discountSubTotal = function () {
  const discountSubTotal = this.getCartDetail.reduce((sum, x) => (x.isBonus ? sum + getMaximum(x) : sum), 0);
  commitMutation('CartDetail', 'discountSubTotal', discountSubTotal);
  return discountSubTotal;
};

/** 有關運費
 * - 取得運費 API 裡的內容
 * - 判斷是否顯示運費
 */

const getShipFare = () => renderState('CartDetail', 'shipFareDetail');

const showShipFare = function () {
  return this.getShipFare.shipFare > 0;
};

/** 結帳
 * - 呼叫 API
 * - 判斷各情境
 * - 寫入收件人資訊 to store
 * - 寫入付款人與付款方式資訊 to store
 * - 寫入發票選項 to  store
 * - 寫入其它資訊
 * - 寫入追蹤資訊
 * - 如為票券車 -> 導去 step2 ; 其他導去 step1
 * - 例外情況:導去首頁
 */
const checkOut = function () {
  // 如在混合車，檢查 user 是否有選取配送方式
  const pickDelivery = renderState('DeliveryInfo', 'delivery');

  if (isMobile() && pickDelivery === '') {
    alert('請選擇配送方式');
    return;
  }

  // 如未登入即 open modal 詢問 ; 如未登入且點擊「直接結帳」者即跳過
  const isLogin = renderState('CartDetail', 'isLogin');
  if (!isLogin && document.getElementById('LoginModal') === null) {
    openModal('LoginModal');
    return;
  }
  // 防止連擊行為
  if (this.cartMainSubmitBtnDisabled) {
    return;
  }
  this.cartMainSubmitBtnDisabled = true;
  // 寫入商品數量 for PC / step1
  commitMutation('CartDetail', 'cartCount', this.getCartDetailCountFilterGift);

  cartMainCheckout(setCheckParams())
    .then((res) => this.response(res, (this.cartMainSubmitBtnDisabled = false)))
    .then((res) => this.saveData(res))
    .then((res) => this.redirect(res))
    .catch((error) => this.catchResponse(error));
  // 避免回上一步時優惠方案資料殘存，導致 call checkOut API post 資料不符
  commitMutations('CartDetail', {
    selectCoupon: [],
    selectEmp: [],
  });
};

const response = (res) => {
  /** 錯誤處理 res.data.code
   * 1 = OK
   * amount !== API amount -> 導去首頁
   * */
  const redirectPathIndex = isMobile() ? '/m' : '';
  const successCode = 1;
  if (res.data.code !== successCode) {
    alert('購物車異動，請重新確認!');
    window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPathIndex}`;
    return;
  }
  const { cartAmount } = res.data.data.master;
  const amount = renderState('CartDetail', 'amount');
  // 總計與 API 不符時
  if (cartAmount !== amount) {
    alert('購物車異動，請重新確認!');
    window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPathIndex}`;
    return;
  }
  // 取得扣掉折扣後的總計並寫入 store
  commitMutation('AmountDetail', 'cartAmount', cartAmount);
  commitMutation('CartDetail', 'amount', cartAmount);
  return res;
};

const saveData = (res) => {
  saveRecipientInfo(res);
  savePayerInfo(res);
  saveInvoiceInfo(res);
  saveResData(res);
  saveToTrackingCode(res);
  return res;
};

const saveRecipientInfo = (res) => {
  const resData = res.data.data.master;
  const cityId = getCityIdByName({ cityName: resData.receiptCity }) || '';
  const townId =
    getTownIdByName({
      cityName: resData.receiptCity,
      townName: resData.receiptDistrict,
    }) || '';
  const returnStoreName = () => {
    const isCalledStoreAPI = renderState('CityTownStores', 'isCalledStoreAPI');
    const storeName = renderState('RecipientInfo', 'senaoStore');
    return isCalledStoreAPI === false ? '請選擇門市' : storeName;
  };
  const returnStoreId = () => {
    const isPriorityStoreId = resData.receiptStoreId !== '';
    const storeId = renderState('RecipientInfo', 'senaoStoreId');
    return isPriorityStoreId ? resData.receiptStoreId : storeId;
  };

  commitMutations('RecipientInfo', {
    // 數字轉為 boolean 判斷，檢查 R 身份用
    isEmployee: !!res.data.data.isReceiptEmployee,
    employeeId: resData.receiptEmployee,
    name: resData.receiptName,
    mobilePhone: resData.receiptMobile,
    localPhoneArea: resData.receiptPhoneCode,
    localPhone: resData.receiptPhoneNo,
    localPhoneExt: resData.receiptPhoneExt,
    email: resData.receiptEmail,
    addressZip: resData.receiptZipCode,
    addressCity: resData.receiptCity,
    addressCityId: cityId,
    addressTown: resData.receiptDistrict,
    addressTownId: townId,
    address: regexpAddress(resData.receiptAddress),
    senaoStoreId: returnStoreId(),
    senaoStore: returnStoreName(),
  });
  return res;
};

const savePayerInfo = (res) => {
  // 傳入付款人地址[城市]轉為 ID
  const payerCityId = getCityIdByName({ cityName: res.data.data.master.payerCity }) || '';
  const memberCityId = getCityIdByName({ cityName: res.data.data.master.memberCity }) || '';
  // 傳入付款人地址[鄉鎮]轉為 ID
  const payerTownId =
    getTownIdByName({
      cityName: res.data.data.master.payerCity,
      townName: res.data.data.master.payerDistrict,
    }) || '';
  const memberTownId =
    getTownIdByName({
      cityName: res.data.data.master.memberCity,
      townName: res.data.data.master.memberDistrict,
    }) || '';
  const resFullAddress =
    res.data.data.master.memberCity + res.data.data.master.memberDistrict + res.data.data.master.memberAddress || '';
  const useForGoogleMapFullAddress = regexpAddress(resFullAddress);
  // 數字轉為 boolean 判斷，檢查 R 身份用
  const isPayerReadOnly = !!res.data.data.isPayerReadOnly;
  const { payments } = res.data.data;

  let hasWalletAndApplepayAndBNPL = false;
  let onlyHasStorePay = false; // edge case
  let onlyHasCreditCardOnetime = false; //edge case
  const appendZingalaPayment = [];
  const cloneAndSetPaymentStatus = payments.map((x) => {
    x.defaultStatus = x.status;
    const isNotSupportApplePay = window.ApplePaySession === undefined;
    const isEnableApplePayPayment = equals(x.paymentType, paymentEnum.ApplePayPayment);
    if (isNotSupportApplePay && isEnableApplePayPayment) {
      // 將 ApplePay 付款選項隱藏 ; status = 0
      setProp('status', prop('notVisible', paymentStatusEnum), x);
    }
    if (isMobile()) {
      // 包含銀角零卡 ; set True for 新增 "行動支付" Radio btn
      hasWalletAndApplepayAndBNPL = hasWalletAndApplepayAndBNPL || /^[456]/.test(x.paymentType);
      onlyHasStorePay = onlyHasStorePay || x.paymentType === 300;
      onlyHasCreditCardOnetime = onlyHasCreditCardOnetime || x.paymentType === 100;
      const isZingalaPayment = equals(x.paymentType, paymentEnum.ZingalaPayment);
      if (isZingalaPayment) {
        setProp('status', prop('notVisible', paymentStatusEnum), x);
        appendZingalaPayment.push(x);
      }
    }
    setProp('defaultStatus', prop('status', x), x);
    return x;
  });
  const filterPaymentView = cloneAndSetPaymentStatus.filter((item) => item.status !== 0);
  // payments view for PC
  commitMutation('PaymentInfo', 'payments', filterPaymentView);

  if (isMobile()) {
    const filterMobilePaymentReducer = (acc, paymentItem, paymentIndex) => {
      if (paymentItem.status !== 0) {
        const hasWalletAndApplepay = /^[45]/.test(paymentItem.paymentType);
        if (hasWalletAndApplepay) {
          paymentIndex = arrangeMobilePaymentEnum[paymentItem['paymentType']] || 10000;
          acc.filterEnablePaymentWalletExcludeBNPL.push({ index: parseInt(paymentIndex, 10), ...paymentItem });
        } else {
          acc.filterEnablePaymentExcludeWallet.push(paymentItem);
        }
      }
      return acc;
    };
    const { filterEnablePaymentWalletExcludeBNPL, filterEnablePaymentExcludeWallet } = cloneAndSetPaymentStatus.reduce(
      filterMobilePaymentReducer,
      {
        filterEnablePaymentWalletExcludeBNPL: [],
        filterEnablePaymentExcludeWallet: [],
      }
    );
    let refactorForMobileView = [];
    if (onlyHasStorePay || onlyHasCreditCardOnetime) {
      refactorForMobileView = [...filterEnablePaymentExcludeWallet];
    }
    if (hasWalletAndApplepayAndBNPL) {
      const sortEnablePaymentWalletExcludeBNPL = filterEnablePaymentWalletExcludeBNPL.sort((a, b) => a.index - b.index);
      // 組「行動支付」panel ; mobilePayments 裡無銀角零卡
      commitMutation('PaymentInfo', 'mobilePayments', sortEnablePaymentWalletExcludeBNPL);
      const newMobilePaymentGroupData = [
        {
          paymentType: paymentEnum.MobilePayment,
          paymentName: '行動支付',
          status: 1,
        },
      ];
      refactorForMobileView = [
        ...filterEnablePaymentExcludeWallet,
        ...newMobilePaymentGroupData,
        ...appendZingalaPayment,
      ];
    }
    // from much much 不支援行動支付
    commitMutation(
      'PaymentInfo',
      'payments',
      isFromMuchMuch() ? filterEnablePaymentExcludeWallet : refactorForMobileView
    );
  }

  const defaultPaymentType = filterPaymentView[0].paymentType;
  const defaultPaymentComponent = componentLut[defaultPaymentType];
  commitMutation('PaymentInfo', 'payment', defaultPaymentType);
  commitMutation('DynamicComponents', 'paymentMethod', defaultPaymentComponent);

  commitMutation('PaymentInfo', 'isPayerReadOnly', isPayerReadOnly);
  // 寫入 much much 付款人資訊 vuex
  commitMutations('CardInfo', {
    name: res.data.data.master.payerName,
    mobilePhone: res.data.data.master.payerMobile,
    localPhoneArea: res.data.data.master.payerPhoneCode,
    localPhone: res.data.data.master.payerPhoneNo,
    localPhoneExt: res.data.data.master.payerPhoneExt,
    email: res.data.data.master.payerEmail,
    addressCity: res.data.data.master.payerCity,
    addressTown: res.data.data.master.payerDistrict,
    address: res.data.data.master.payerAddress,
    addressCityId: payerCityId,
    addressTownId: payerTownId,
  });

  // 寫入門取與行動支付的付款人資訊 vuex for Mobile
  commitMutations('StoreInfo', {
    name: res.data.data.master.memberName,
    mobilePhone: res.data.data.master.memberMobile,
    email: res.data.data.master.memberEmail,
    addressCity: res.data.data.master.memberCity,
    addressTown: res.data.data.master.memberDistrict,
    address: res.data.data.master.memberAddress,
    addressZip: res.data.data.master.memberZipCode,
    addressCityId: memberCityId,
    addressTownId: memberTownId,
  });
  // 門取與行動支付的付款人資訊另存到 OrderInfo for 訂購人
  commitMutations('OrderInfo', {
    name: res.data.data.master.memberName,
    isHasOrderName: !!res.data.data.master.memberName,
    isHasOrderMobile: !!res.data.data.master.memberMobile,
    mobilePhone: res.data.data.master.memberMobile,
    isHasOrderMail: !!res.data.data.master.memberEmail,
    email: res.data.data.master.memberEmail,
    addressCity: res.data.data.master.memberCity,
    addressTown: res.data.data.master.memberDistrict,
    address: regexpAddress(res.data.data.master.memberAddress),
    fullAddress: useForGoogleMapFullAddress,
    addressZip: res.data.data.master.memberZipCode,
    addressCityId: memberCityId,
    addressTownId: memberTownId,
    isHasAddress: !!res.data.data.master.memberAddress,
    isHasZipCode: !!res.data.data.master.memberZipCode,
  });

  return res;
};

const saveInvoiceInfo = (res) => {
  // 發票開立方式
  const { invoiceType } = res.data.data;
  invoiceType.forEach((x) => x.status);
  commitMutations('InvoiceInfo', {
    /** 寫入從 api 來的第一組發票開立代號 */
    invoiceDefault: invoiceType[0],
    /** 寫入從 api 來的發票開立代號 */
    invoiceType: invoiceType,
    /** 捐贈發票單位列表 */
    donateInvoiceList: res.data.data.invoiceDonate,
    /** 手機條碼載具*/
    phoneDeviceCode: res.data.data.mobileBarcode,
    isSaveDeviceCode: !!res.data.data.mobileBarcode,
  });
  /** 寫入預設 load [統編記事本] API 的紀錄，因 vuex 特性故重設為預設狀態 */
  commitMutation('InvoiceModalInfo', 'hasLoadInvoice', false);
  return res;
};

const saveResData = (res) => {
  const getCartDetail = renderGetter('CartDetail', 'getterCartDetail');
  const resData = res.data.data;
  const storeCartDetail = getCartDetail.map((x) => ({
    productImage: x.masterImage,
    productRoute: `/mart/${x.martCode}`,
    productName: x.productName,
    productSpec: x.productSpec,
    productQuantity: x.productQty,
    productPrice: x.finalPrice,
    discountPrice: x.couponDiscount,
    cartTag: x.cartTag,
    eventDesc: x.discountName,
    couponDesc: x.couponDesc,
    isVip: x.isVIP, // 是否為隱賣商品
    isFavor: !!x.isFavor, // 數字轉為 boolean 判斷，是否為R商品，是則隱藏「收藏」鈕 ; 0/1
  }));
  commitMutation('CartDetail', 'resStoreCartDetail', storeCartDetail);
  // 分期用
  commitMutation('InstallmentInfo', 'categories', resData.categories);
  const pickUsePoint = renderState('CartDetail', 'isCheckPoint');
  commitMutations('AmountDetail', {
    subTotal: renderState('CartDetail', 'subTotal'), // 該車全部商品小計原始總和 (未扣紅利或折價券) ; from total-amount
    bonusPoint: !pickUsePoint ? 0 : renderState('CartDetail', 'pointMoney'), // 計算過後的紅利金額
    cartDiscount: 0, // 整車折抵金額(活動折抵)
    totalAmount: renderState('CartDetail', 'amount'), // 前端計算的應付金額 (已扣下述折扣的金額)應同於 cartAmount ; from total-amount
    totalPrice: resData.master.totalPrice, // 明細總金額 (尚未扣掉折扣 ex: 紅利、折價券...的金額)
    cartAmount: resData.master.cartAmount, // 應付金額 (已扣上述折扣的金額)
    itemCoupon: resData.master.itemCoupon,
    usedPoint: resData.master.usedPoint,
    pointRate: resData.master.pointRate,
    isVTP: resData.isVTP,
    checksum: resData.checksum,
    csrf: resData.csrf,
    shipFare: renderState('CartDetail', 'shipFareDetail').shipFare,
    shipFareFreeLimit: renderState('CartDetail', 'shipFareDetail').shipFareFreeLimit,
    amounts: renderState('CartDetail', 'shipFareDetail').amounts, // 將呼叫第一次的運費 API 裡的 amounts 存入
    isCycle: resData.isCycle, // for 2st運費 API Post
    cartType: renderState('CartDetail', 'cartType'), // for 2st運費 API Post
  });
  commitMutations('CardInfo', {
    isCycle: resData.isCycle,
    validThru: resData.validThru,
  });
  const hasCoordinateFlag = Object.keys(resData).includes('coordinate');
  // 有經緯度資料表示來自 APP
  if (hasCoordinateFlag) {
    commitMutation('CityTownStores', 'isCoordinate', true);
    const resCoordinate = resData.coordinate;
    new Promise((resolve) => resolve())
      .then(() => dispatchAction('CityTownStores', 'getAllCityTownStores'))
      .then(() => getNearbyStoreFn(resCoordinate.lat, resCoordinate.lon));
  }
  return res;
};

const saveToTrackingCode = (res) => {
  const cartDetail = renderState('CartDetail', 'cartDetail');
  // UA
  checkOutToCart(cartDetail, 1);
  // GA4
  const itemParameters = createParameterArray(cartDetail);
  const { cartAmount } = res.data.data.master;
  const defaultParameterForGA = {
    currency: 'TWD',
    value: cartAmount,
    items: itemParameters,
  };
  sendGA4sEvent('begin_checkout', defaultParameterForGA);
  return res;
};

const redirect = function (res) {
  const deliveryType = renderState('DeliveryInfo', 'delivery');
  if (isMobile()) {
    // 商品不須配送 (ex: 票券車)
    if (deliveryType === '0') {
      this.$router.push('/m/cart/step2');
      return {};
    }
    // 如非票券車，依照 delivery 切換 component [宅配到府] 直接顯示 [收件人資訊], [門市取貨] 直接顯示 [門市取貨資訊]
    commitMutation('DynamicComponents', 'deliveryMethod', deliveryType === '1' ? 'step1-addressee' : 'step1-store');
    this.$router.push('/m/cart/step1');
    return res;
  }
  this.$router.push('/cart/step1');
  return res;
};

const catchResponse = (error) => {
  console.log(error);
  const redirectPathIndex = isMobile() ? '/m' : '';
  window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPathIndex}`;
  return error;
};

const data = () => ({
  baseUrl: process.env.VUE_APP_ONLINE,
  baseCart: process.env.VUE_APP_CART,
  // user 選擇的優惠券 for POST
  selectedCoupon: [],
  // user 選擇的員工價 for POST
  selectedEmp: [],
  // PC: className | Mobile: :disabled
  cartMainSubmitBtnDisabled: false,
});

export const cartMainPartial = {
  computed: {
    amount,
    discountSubTotal,
    formatPointMoney,
    getCartDetail,
    getCartDetailCountFilterGift,
    getCouponList,
    getShipFare,
    /*
    連結邏輯:
      - 主商品 || 加價購 => 顯示超連結
      - 贈品 || 隱賣  => 不顯示超連結
    */
    hasLink() {
      return (item) => item;
    },
    hasPoint,
    isAuthHamiPoint,
    // 能否顯示收藏鈕與刪除鈕
    isFavorOrDelete() {
      return (item) => item.isFavor || item.isDelete;
    },
    // 是否顯示刪除鈕
    isDelete() {
      return (item) => item;
    },
    // 能否顯示收藏鈕
    isFavor() {
      return (item) => item;
    },
    isMajorItem,
    isShowDiscountTag,
    isShowDiscount,
    isShowEmpPrice,
    subTotal,
    showShipFare,
    hamiPoint,
    point,
    pointMoney,
    pointMaxExchangeRate,
    isShowCoupon,
    isShowHamiPointBlock,
  },
  data,
  methods: {
    checkOut,
    changeQty,
    catchResponse,
    collectProducts,
    customQty,
    deleteProducts,
    isRemoveProduct,
    isShowPricingPlans,
    onPricingChange,
    saveData,
    saveRecipientInfo,
    savePayerInfo,
    saveInvoiceInfo,
    saveResData,
    saveToTrackingCode,
    redirect,
    response,
    updatePoint,
    isShowHasCoupon,
    redirectToCht,
  },
  watch: {
    // 可折商品小計總和如有變動 (ex: 增減可折商品數量時 ) 即更新紅利資訊
    discountSubTotal: updatePoint,
  },
};
