import { paymentEnum } from '@/constant/payment-info';

/** [付款方式] 與 component 對應關係 */
export const componentLut = {
  /** 信用卡紅利 */
  [paymentEnum.creditCardBonus]: 'creditcard-bonus',
  /** 信用卡一次 */
  [paymentEnum.creditCardOnetime]: 'creditcard-onetime',
  /** 信用卡分期 */
  [paymentEnum.creditCardInstallment]: 'creditcard-installment',
  /** ATM 轉帳 */
  [paymentEnum.ATMTransfer]: 'atm-transfer',
  /** 門市繳費 */
  [paymentEnum.storePayment]: 'store-payment',
  /** LINE 付款 */
  [paymentEnum.linePayment]: 'line-payment',
  /** 街口支付 */
  [paymentEnum.jkosPayment]: 'jkos-payment',
  /** Pi支付 */
  [paymentEnum.PiPayment]: 'pi-payment',
  /** TW Pay支付 */
  [paymentEnum.TWPayment]: 'tw-payment',
  /** 全盈+PAY支付 */
  [paymentEnum.PlusPayPayment]: 'plus-pay-payment',
  /** Apple Pay支付 */
  [paymentEnum.ApplePayPayment]: 'apple-pay-payment',
  /** 悠遊付 */
  [paymentEnum.EasyPayPayment]: 'easy-pay-payment',
  /** 零卡分期 */
  [paymentEnum.ZingalaPayment]: 'zingala-payment',
  /** 行動支付 */
  [paymentEnum.MobilePayment]: 'mobile-payment',
};
