import { ref } from 'vue';
import { renderState, renderGetter } from '@/helpers/vuex';

export function useCoupon(props, target) {
  const getCouponList = () => renderGetter('CartDetail', 'getterCoupon');
  const selectedCoupons = () => renderState('CartDetail', 'selectCoupon');
  const couponId = ref('');
  const coupons = ref([]);
  const today = new Date();
  const onPricingChange = () => {
    target.emit('on-pricing-change', couponId.value);
  };
  const cancelHandler = () => {
    // reset
    couponId.value = '';
    target.emit('on-pricing-change', couponId.value);
  };
  const isTodayBeforeStartDate = (date) => {
    const startDate = new Date(date);
    return today < startDate;
  };
  const formatDollar = (val) => `$${val.toLocaleString()}`;
  const isHasCouponId = (coupon) => Object.prototype.hasOwnProperty.call(coupon, 'couponId');
  const isOtherMartUsed = (couponId, mart) =>
    selectedCoupons().some((coupon) => coupon.couponId === couponId && coupon.detailId !== mart.detailId);
  const getCouponDisplayText = (el, mart) => {
    if (isOtherMartUsed(el.couponId, mart)) {
      return 'used';
    }
    if (isTodayBeforeStartDate(el.startDate)) {
      return 'later';
    }
    return '';
  };
  const filterCoupon = (mart) => {
    const filteredCoupons = getCouponList()
      .filter((coupon) => coupon.martId.includes(mart.martCode) || coupon.martId.includes(parseInt(mart.martCode, 10)))
      .map((el) => {
        const discountPrice = Math.max(mart.salePrice * mart.productQty - el.itemCoupon, 0);
        const numberedEndDate = parseInt(el.endDate.replace(/\D/g, ''), 10);
        // for 畫面上顯示 已使用 / 稍後使用 / checkbox用
        const displayText = getCouponDisplayText(el, mart);
        return {
          ...el,
          startDate: el.startDate.slice(0, -3),
          endDate: el.endDate.slice(0, -3),
          value: displayText || el.couponId,
          numberedEndDate,
          discountPrice,
          sumForSort: parseInt(`${discountPrice}${numberedEndDate}`, 10),
        };
      })
      // 依折後金額 低 => 高
      .sort((a, b) => a.sumForSort - b.sumForSort);
    const beforeStartDate = [];
    const afterStartDate = [];
    filteredCoupons.forEach((coupon) => {
      const startDate = new Date(coupon.startDate);
      if (today < startDate) {
        afterStartDate.push(coupon);
      } else {
        beforeStartDate.push(coupon);
      }
    });
    const empPrice = mart.empPrice ? [{ couponDesc: '員購價', value: 'emp' }] : [];
    // 畫面顯示順序 可使用優惠券 - 員購價 - 稍後使用優惠券
    const displayCoupons = [...beforeStartDate, ...empPrice, ...afterStartDate];
    return displayCoupons;
  };
  return {
    onPricingChange,
    cancelHandler,
    isHasCouponId,
    coupons,
    couponId,
    filterCoupon,
    formatDollar,
  };
}
